import React, { Component } from "react";
import { toast } from "react-toastify";
import { getAllGroups } from "../apis/message";
import { groupType, userBaseProfileURL } from "../../config/constant";

export class AbstractHeaderMessage extends Component<any, any> {
  searchRef: any;

  constructor(props: any) {
    super(props);
    this.searchRef = React.createRef();

    this.state = {
      showLoading: false,
      messageGroupList: [],
      messageRecomandedGroupList: [],
      messageGroupFilter: {
        search: "",
        pageNo: 0,
        hasMore: false,
        lastSyncTime: null,
        limit: 6,
        count: 0,
        currentPage: 0,
        location:'header'
      },
    };
  }

  componentDidMount = async () => {
    await this.getAllGroupsList();
  };

  componentWillUnmount(): void {}

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      if (this.searchRef.current && !this.searchRef.current.isSubmitting) {
        this.searchRef.current.handleSubmit();
      }
    }
  };

  getGroupAvatar = (group: any) => {
    if (group?.practitioner?.profile_photo) {
      return `${userBaseProfileURL}${group?.practitioner?.profile_photo}`;
    }
    return `${userBaseProfileURL}sitelogo_small.png`;
  };

  getGroupName = (group: any) => {
    if (group?.message_group?.group_type == groupType.individual) {
      return `${group?.practitioner?.fname} ${group?.practitioner?.lname}`;
    } else {
      return group?.message_group?.group_name;
    }
  };

  getGroupDescription = (group: any) => {
    if (group?.message_group?.group_type == groupType.individual) {
      return `${group?.practitioner?.speciality?.speciality_name}`;
    } else if (group?.message_group?.group_type == groupType.global) {
      return `Community`;
    } else {
      return group?.message_group?.group_type;
    }
  };

  getRecomandedGroupAvatar = (practitioner: any) => {
    if (practitioner?.profile_photo) {
      return `${userBaseProfileURL}${practitioner?.profile_photo}`;
    }
    return `${userBaseProfileURL}default.png`;
  };

  getRecomandedGroupName = (practitioner: any) => {
    return `${practitioner?.fname} ${practitioner?.lname}`;
  };

  getRecomandedGroupDescription = (practitioner: any) => {
    return `${practitioner?.speciality?.speciality_name}`;
  };

  getAllGroupsList = async (setSubmitting: any = "") => {
    this.setState({ showLoading: true });
    await this.props
      .dispatch(getAllGroups(this.state.messageGroupFilter))
      .then((result: any) => {
        let responseObj = result.data.data;
        let messageGroupFilter = this.state.messageGroupFilter;
        messageGroupFilter.pageNo = responseObj.currentPage + 1;
        messageGroupFilter.lastSyncTime =
          responseObj.currentPage == 1
            ? responseObj.lastSyncTime
            : messageGroupFilter.lastSyncTime;
        messageGroupFilter.hasMore =
          responseObj.totalPage > 0 &&
          responseObj.currentPage != responseObj.totalPage;

        this.setState(
          (prevState: any) => ({
            messageGroupList: responseObj.rows,
            messageRecomandedGroupList: responseObj.recommendedChat,
            showLoading: false,
            messageGroupFilter,
          })
        );
      })
      .catch((err: any) => {
        if (err) {
          toast.dismiss();
      toast.error(err.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.setState({ showLoading: false });
      })
      .finally(() => {
        if (setSubmitting) {
          setSubmitting(false);
        }
      });
  };

  searchGroup = (values: any, setSubmitting: any) => {
    this.setState(
      {
        messageGroupList: [],
        messageRecomandedGroupList: [],
        messageGroupFilter: {
          search: values.search,
          pageNo: 0,
          hasMore: false,
          lastSyncTime: null,
          limit: 6,
          count: 0,
          currentPage: 0,
        },
      },
      () => {
        this.getAllGroupsList(setSubmitting);
      }
    );
  };

  clearGroupSearch = (setFieldValue: any) => {
    setFieldValue("search", "");
    this.setState(
      {
        messageGroupList: [],
        messageRecomandedGroupList: [],
        messageGroupFilter: {
          search: "",
          pageNo: 0,
          hasMore: false,
          lastSyncTime: null,
          limit: 6,
          count: 0,
          currentPage: 0,
        },
      },
      () => {
        this.getAllGroupsList();
      }
    );
  };
}
