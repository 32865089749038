import Http from "../utils/Http";
import Transformer from "../utils/Transformer";
import * as constant from "../../config/constant";

export function getNewGroup(data: any, extraParam:any={}) {
  let requestParam: any = {};
  if(extraParam.selected_user?.id > 0){
    requestParam.selected_user = extraParam.selected_user?.id;
  }
  if (data.group_id) {
    requestParam.group_id = data.group_id;
  }
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();
      Http.get(`api/message/get-new-group?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getAllGroups(data: any, extraParam:any={}) {  
  let requestParam: any = { limit: data.limit };
  if(extraParam.selected_user?.id > 0){
    requestParam.selected_user = extraParam.selected_user?.id;
  }
  if (data.search && data.search.trim() != "") {
    requestParam.searchKeyword = data.search;
  }
  if (data.lastSyncTime) {
    requestParam.lastSyncTime = data.lastSyncTime;
  }
  if (data.location) {
    requestParam.location = data.location;
  }
  if (data.pageNo) {
    requestParam.pageNo = data.pageNo;
  }
  
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();
      Http.get(`api/message/get-all-group?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function createGroup(data: any, selected_user:any=null) {
  let requestParam: any = { limit: data.limit };
  if (data.id) {
    requestParam.receiver_id = data.id;
  }
  if(selected_user?.id > 0){
    requestParam.loggedInUser = selected_user;
  }
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post(`api/message/create-group`, requestParam)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getAllMessage(data: any, chatGroup: any, extraParam:any={}) {
  let requestParam: any = { limit: 10 };
  if(extraParam.selected_user?.id > 0){
    requestParam.selected_user = extraParam.selected_user?.id;
  }
  if (chatGroup.group_id) {
    requestParam.group_id = chatGroup.group_id;
  }
  if (data.lastSyncTime) {
    requestParam.lastSyncTime = data.lastSyncTime;
  }
  if (data.pageNo) {
    requestParam.pageNo = data.pageNo;
  }
  // if (data.lastUnreadMessage) {
  //   requestParam.lastUnreadMessage = data.lastUnreadMessage;
  // }

  if (data.lastReadMessage) {
    requestParam.lastReadMessage = data.lastReadMessage;
  }
  
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();

      Http.get(`api/message/get-message?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getNote(chatGroup: any) {
  let requestParam: any = {};
  if (chatGroup.group_id) {
    requestParam.group_id = chatGroup.group_id;
  }
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();

      Http.get(`api/message/get-note?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getUnreadMessage(data: any, chatGroup: any, extraParam:any={}) {
  let requestParam: any = { limit: 10 };
  if(extraParam.selected_user?.id > 0){
    requestParam.selected_user = extraParam.selected_user?.id;
  }
  if (chatGroup.group_id) {
    requestParam.group_id = chatGroup.group_id;
  }
  if (data.lastSyncTime) {
    requestParam.lastSyncTime = data.lastSyncTime;
  }
  if (data.pageNo) {
    requestParam.pageNo = data.pageNo;
  }
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();
      Http.get(`api/message/get-unread-message?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function addMessage(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/message/add-message", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function deleteMessage(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.delete(`api/message/${data["id"]}/${data["group_id"]}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function addNote(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/message/add-note", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getActiveGroup(socket: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get(`api/message/get-active-group`)
        .then((res) => {
          const data = Transformer.fetch(res.data.data);
          data?.data?.map((group: any) => {
            socket.emit("join_zipcode_message", {
              group_id: group.group_id,
            });
          });
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function notifyUser(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/practitioner/notify-user", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

export function getAllPractitioner(data: any) {  
  let requestParam: any = { limit: data.limit };

  if (data.search && data.search.trim() != "") {
    requestParam.searchKeyword = data.search;
  }
  if (data.lastSyncTime) {
    requestParam.lastSyncTime = data.lastSyncTime;
  }
  if (data.location) {
    requestParam.location = data.location;
  }
  if (data.pageNo) {
    requestParam.pageNo = data.pageNo;
  }
  
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      let requestParamString = new URLSearchParams(requestParam).toString();
      Http.get(`api/practitioner/new?${requestParamString}`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}