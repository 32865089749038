import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserUsingToken } from "../lib/apis/auth";
import { socketConnect } from "socket.io-react";
import { getActiveGroup } from "../lib/apis/message";

let userAuth = function (ComposedComponent) {
  class UserAuth extends Component {
    async componentDidMount() {
      // console.log(this.props);
      if (
        this.props.isAuthenticated !== true &&
        localStorage.getItem("access_token")
      ) {
        await this.GetTokenUser();
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (
        this.props.isAuthenticated &&
        prevProps.isAuthenticated != this.props.isAuthenticated
      ) {
        const intervalID1 = setInterval(() => {
          if (typeof window?.Tawk_API?.hideWidget === "function") {
            window?.Tawk_API?.hideWidget();
            // Clear the interval after hideWidget is executed
            clearInterval(intervalID1);
          }
        }, 50);
        // setTimeout(() => {
        //   if (typeof window?.Tawk_API?.hideWidget === 'function') {
        //     window?.Tawk_API?.hideWidget();
        //   }
        // }, 2000);
      }
    }

    GetTokenUser() {
      this.props
        .getUserUsingToken()
        .then((response) => {
          // this.props.socket.emit("joinuserroom", {
          //   practitioner_id: response.data.user.id,
          // });
          localStorage.setItem(
            "practitionerData",
            JSON.stringify(response.data.user)
          );
          // this.props.dispatch(getActiveGroup(this.props.socket));
        })
        .catch((error, statusCode, message, e) => {
          // console.log("error", error);
          let practitionerData = localStorage.getItem("practitionerData");
          practitionerData = practitionerData
            ? JSON.parse(practitionerData)
            : {};
          this.props.socket.emit("leaveuserroom", {
            practitioner_id: practitionerData.id,
          });
          localStorage.removeItem("access_token");
          localStorage.removeItem("practitionerData");
          this.props.history.push(`${process.env.PUBLIC_URL}`);
        });
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      currentUser: state.auth.currentUser,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };

  return socketConnect(
    connect(mapStateToProps, { getUserUsingToken })(UserAuth)
  );
};

export default userAuth;
