import React, { Component } from "react";
import { Provider } from "react-redux";
import Route from "./routes/index";
import { store } from "./lib/store";
import "./App.scss";
import { SocketProvider } from "socket.io-react";
import { io } from "socket.io-client";
import * as constant from "./lib/config/constant";

const socket = io(constant.API_URL);
socket.on("disconnect", async function () {
  console.log("Disconect socket");
});
let access_token: any = localStorage.getItem("access_token");

class App extends Component<any, any> {
  componentDidMount = async () => {
    setInterval(function () {
      let new_access_token: any = localStorage.getItem("access_token");

      if (access_token != new_access_token) {
        if (new_access_token) {
        } else {
          window.location.reload();
        }
        access_token = new_access_token;
      }
    }, 1500);
  };

  // https://levelup.gitconnected.com/using-jwt-in-your-react-redux-app-for-authorization-d31be51a50d2
  render() {
    return (
      <>
        <Provider store={store}>
          <SocketProvider socket={socket}>
            <Route />
          </SocketProvider>
        </Provider>
      </>
    );
  }
}

export default App;
