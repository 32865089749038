import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import logo from "../new-logo.png";
import { Form, Dropdown, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faBullhorn,
  faCalendarCheck,
  faCalendarPlus,
  faClipboardList,
  faClose,
  faExpand,
  faMagnifyingGlass,
  faMessage,
  faPenSquare,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { logout } from "../lib/apis/auth";
import Modal from "react-bootstrap/Modal";
import { socketConnect } from "socket.io-react";
import { ASSET_IMAGE_URL, userBaseProfileURL } from "../config/constant"; // ASSET_IMAGE_URL,
import Message from "./message";
import { getActiveGroup } from "../lib/apis/message";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const logo = `${ASSET_IMAGE_URL}/new_logo_v2.svg`;

class HeaderAuth extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language: localStorage.getItem("language") || "en",
      practitionerData: localStorage.getItem("practitionerData") || "",
      showLogoutModal: false,
      showNavbar: false,
      showDropdown: false,
      isNewNotification:
        this.props.currentUser?.new_notification > 0 ? true : false,
      isNewMessage: this.props.currentUser?.new_message > 0 ? true : false,
      messageKey: Math.random(),
      activeGroup: [],
      search: "",
    };
  }

  componentDidMount() {
    document.onkeydown = (evt) => {
      if (evt.keyCode === 27) {
        this.setState({
          showLogoutModal: false,
        });
      }
    };
    window.addEventListener("resize", () => {
      this.setState({
        showNavbar: false,
      });
    });
    setTimeout(() => {
      document.getElementById("loading")?.classList.add("d-none");
    }, 500);
    this.handleNotificationSocket();
    this.handleMessageSocket();
    this.connectUserSocket();
    setTimeout(() => {
      this.getActiveGroupAndJoinSocket();
    }, 1000);

    // const searchParams = new URLSearchParams(this.props.location?.search);
    // searchParams.get("q");
    // let searchKeyword =
    //   this.props.location.pathname == "/lsn/search"
    //     ? searchParams.get("q")??''
    //     : "";
    // this.setState({ search: searchKeyword });
  }

  componentWillUnmount(): void {
    this.disconnectUserSocket();
    this.disconnectAvtiveGroupSocket(this.state.activeGroup);
  }

  connectUserSocket = () => {
    this.props.socket.emit("joinuserroom", {
      practitioner_id: this.props.currentUser.id,
    });
  };

  disconnectUserSocket = () => {
    this.props.socket.emit("leaveuserroom", {
      practitioner_id: this.props.currentUser.id,
    });
  };

  getActiveGroupAndJoinSocket = () => {
    this.props.socket.on("join_zipcode_group", (data: any) => {
      this.props.socket.emit("join_zipcode_message", {
        group_id: data["group_id"],
      });
    });
    this.props.socket.on("leave_zipcode_group", (data: any) => {
      this.props.socket.emit("leave_zipcode_message", {
        group_id: data["group_id"],
      });
    });

    this.props
      .dispatch(getActiveGroup(this.props.socket))
      .then((result: any) => {
        let responseObj = result?.data?.data?.data ?? [];
        this.setState({ activeGroup: responseObj });
      })
      .catch((e: any) => {
        // console.log({ e });
      });
  };

  disconnectAvtiveGroupSocket = (activeGroup: any) => {
    if (activeGroup.length > 0) {
      activeGroup.map((group: any) => {
        this.props.socket.emit("leave_zipcode_message", {
          group_id: group.group_id,
        });
      });
    }
  };

  handleNotificationSocket = () => {
    this.props.socket.on("new_notification", (data: any) => {
      this.setState({ isNewNotification: true });
    });
    this.props.socket.on("read_notification", (data: any) => {
      this.setState({ isNewNotification: false });
    });
  };

  handleMessageSocket = () => {
    this.props.socket.on("update_unread", (data: any) => {
      let isNewMessage = this.state.isNewMessage;
      if (data["action"] == "unread") {
        isNewMessage += data["recordReceived"];
      } else if (data["action"] == "read") {
        isNewMessage -= data["recordReceived"];
      }
      this.setState({ isNewMessage: isNewMessage > 0 ? true : false });
    });
  };

  handleDropdownToggle = () => {
    this.setState((prevState: any) => ({
      showDropdown: !prevState.showDropdown,
    }));
  };

  toggleLogoutModal = () => {
    if (this.state.showLogoutModal === false) {
      this.setState({
        showLogoutModal: true,
      });
    } else {
      this.setState({
        showLogoutModal: false,
      });
    }
  };

  /**handle logout */
  handleLogout = () => {
    let practitionerData: any = localStorage.getItem("practitionerData");
    practitionerData = practitionerData ? JSON.parse(practitionerData) : {};
    this.props.socket.emit("leaveuserroom", {
      practitioner_id: practitionerData.id,
    });
    localStorage.removeItem("isLogin");
    localStorage.removeItem("access_token");
    localStorage.removeItem("practitionerData");
    this.props
      .dispatch(logout())
      .then((res: any) => {
        // console.log(res)
      })
      .catch(() => {});
    this.props.history.push("/");
  };

  render() {
    const { showDropdown } = this.state;
    return (
      <>
        <header id="header" className="auth-header">
          <div className="container auth-container">
            <div className="row">
              <div
                className="logo-header order-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link
                  to="/dashboard"
                  className="logo"
                  onClick={() => {
                    this.setState({ showNavbar: false });
                    setTimeout(() => {
                      window.scroll(0, 0);
                      document
                        .getElementsByTagName("body")[0]
                        .scrollTo({ top: 0, behavior: "smooth" });
                    }, 200);
                    // <Redirect to="/home" />;
                  }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid logo-img"
                    width={200}
                    height={80}
                  />
                </Link>
              </div>
              <div className="widthManage">
                <div className="searchbar-header order-1 mobile-none">
                  <Form
                    onSubmit={(e: any) => {
                      e.preventDefault();
                      this.props.history &&
                        this.props.history.push(
                          `/lsn/search?q=${this.state?.search}`
                        );
                    }}
                  >
                    <div className="position-relative">
                      <div className="form-group cstm-searchbox">
                        <Form.Control
                          type="text"
                          placeholder="Search Zip Ally"
                          onChange={(event: any) => {
                            this.setState({ search: event.target.value });
                            // console.log(event.target.name);
                            // console.log(event.target.value);
                          }}
                          value={this.state?.search ?? ""}
                        />
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="searchbtn"
                        />
                        {this.state?.search ? (
                          <FontAwesomeIcon
                            onClick={() => {
                              this.setState({ search: "" });
                            }}
                            icon={faClose}
                            className="Closebtn cursor-pointer"
                          />
                        ) : null}
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="rightbar-header order-3">
                  <Navbar className="bg-body-tertiary right-bar">
                    <Navbar.Toggle aria-controls="basic-navbar-nav right-bar" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        {this.props?.currentUser?.is_profile_completed &&
                        !this.props?.currentUser?.is_premium ? (
                          <>
                            <Nav.Link
                              as={Link}
                              to="/membership-detail"
                              className="btn-icon btn-Premium active xs-none"
                            >
                              Go Premium
                            </Nav.Link>
                          </>
                        ) : null}
                        {/* <Dropdown className="cstm-dropdown cstm-messagebox none-default">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="btn-icon non-arrow"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className=""
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight className="menu-searchbar">
                            <div className="searchbar-header order-1">
                              <Form>
                                <div className="position-relative">
                                  <div className="form-group cstm-searchbox">
                                    <Form.Control
                                      type="email"
                                      placeholder="Search your network"
                                    />
                                    <FontAwesomeIcon
                                      icon={faMagnifyingGlass}
                                      className="searchbtn"
                                    />
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <span>
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            key="top"
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="buttonTooltip"
                                className="font-small font-weight-bold"
                              >
                                Events
                              </Tooltip>
                            }
                          >
                            <Nav.Link
                              as={Link}
                              to="/events"
                              className="btn-icon xs-none"
                            >
                              <FontAwesomeIcon
                                icon={faCalendarPlus}
                                className=""
                              />
                            </Nav.Link>
                          </OverlayTrigger>
                        </span>
                        <span>
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            key="top"
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="buttonTooltip"
                                className="font-small font-weight-bold"
                              >
                                My Network
                              </Tooltip>
                            }
                          >
                            <Nav.Link
                              as={Link}
                              to="/network"
                              className="btn-icon xs-none"
                            >
                              <FontAwesomeIcon
                                icon={faUserGroup}
                                className=""
                              />
                            </Nav.Link>
                          </OverlayTrigger>
                        </span>

                        <Dropdown
                          className="cstm-dropdown cstm-messagebox xs-none"
                          onToggle={(isOpen, event, metadata) => {
                            if (isOpen) {
                              this.setState({ messageKey: Math.random() });
                            }
                          }}
                        >
                          <span>
                            <OverlayTrigger
                              trigger={["hover", "hover"]}
                              key="top"
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id="buttonTooltip"
                                  className="font-small font-weight-bold"
                                >
                                  Messages
                                </Tooltip>
                              }
                            >
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="btn-icon non-arrow"
                              >
                                <FontAwesomeIcon
                                  icon={faMessage}
                                  className=""
                                />{" "}
                                {this.state.isNewMessage ? (
                                  <>
                                    <span className="notify"></span>
                                  </>
                                ) : null}
                              </Dropdown.Toggle>
                            </OverlayTrigger>
                          </span>
                          <Dropdown.Menu alignRight>
                            <Message key={this.state.messageKey} />
                          </Dropdown.Menu>
                        </Dropdown>
                        <span>
                          <OverlayTrigger
                            trigger={["hover", "hover"]}
                            key="top"
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="buttonTooltip"
                                className="font-small font-weight-bold"
                              >
                                Notification
                              </Tooltip>
                            }
                          >
                            <Nav.Link
                              as={Link}
                              to="/notification"
                              className="btn-icon xs-none"
                            >
                              <FontAwesomeIcon icon={faBell} className="" />
                              {this.state.isNewNotification ? (
                                <>
                                  <span className="notify"></span>
                                </>
                              ) : null}
                            </Nav.Link>
                          </OverlayTrigger>
                        </span>
                        <Dropdown className="cstm-dropdown">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="btn-icon non-arrow"
                          >
                            <FontAwesomeIcon icon={faBars} className="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            <p className="droptitle">Create</p>
                            <Dropdown.Item
                              as={Link}
                              to="/dashboard?c=event"
                              className="fullWidth"
                            >
                              {" "}
                              <FontAwesomeIcon icon={faCalendarCheck} />{" "}
                              <span>Event</span>{" "}
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/dashboard?c=promotion"
                              className="fullWidth"
                            >
                              <FontAwesomeIcon icon={faBullhorn} />{" "}
                              <span>Promotion</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/dashboard?c=post"
                              className="fullWidth"
                            >
                              <FontAwesomeIcon icon={faClipboardList} />{" "}
                              <span>Post</span>
                            </Dropdown.Item>
                            <NavDropdown.Divider className="fullWidth" />
                            <span className="Premium bottom">
                              Feature your profle?
                            </span>
                            {/* <Dropdown.Item href="">Check Available Zip</Dropdown.Item> */}
                            <Button className="checkZip">
                              Check Available Zip
                            </Button>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="cstm-dropdown">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="btn-icon non-arrow user-pic"
                          >
                            {/* <FontAwesomeIcon icon={faUser} className="" /> */}
                            <img
                              src={
                                this.props.currentUser?.avatar !== ""
                                  ? `${userBaseProfileURL}${this.props.currentUser?.avatar}`
                                  : `${userBaseProfileURL}default.png`
                              }
                              alt="logo"
                              className="img-fluid user-img"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            <Dropdown.Item as={Link} to="/user">
                              View Profile
                            </Dropdown.Item>
                            {this.props?.currentUser?.is_profile_completed &&
                            !this.props?.currentUser?.is_premium ? (
                              <>
                                <span
                                  className="Premium cursor-pointer"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/membership-detail"
                                    );
                                  }}
                                >
                                  Go Premium {">"}
                                </span>
                              </>
                            ) : null}
                            <NavDropdown.Divider className="fullWidth" />
                            <Dropdown.Item as={Link} to="/dashboard">
                              Dashboard
                            </Dropdown.Item>
                            {/* <Dropdown.Item as={Link} to="/edit-profile">
                              Edit Profle
                            </Dropdown.Item> */}
                            <Dropdown.Item as={Link} to="/membership">
                              Membership
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/network"
                              className="xs-display"
                            >
                              My Network
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/events"
                              className="xs-display"
                            >
                              Events
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/message"
                              className="xs-display"
                            >
                              Messages
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/referrals">
                              Referrals
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/notification">
                              Notification
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/settings">
                              Settings
                            </Dropdown.Item>
                            <NavDropdown.Divider className="fullWidth" />
                            <Dropdown.Item
                              href={undefined}
                              onClick={(e) => {
                                this.toggleLogoutModal();
                                e.preventDefault();
                              }}
                            >
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </header>
        <Modal
          show={this.state.showLogoutModal}
          onHide={this.toggleLogoutModal}
          centered
          className="forgot-modal"
          size="sm"
        >
          {/*  */}
          <Modal.Header>
            <h4 className="lead font-weight-bold mb-0">Logout</h4>
            <span
              onClick={this.toggleLogoutModal}
              className="btn modal-close-btn flaticon-cancel cursor-pointer"
            ></span>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to logout?</p>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <a
                href={" "}
                onClick={(e) => {
                  this.handleLogout();
                }}
                className="btn-primary logout-btn"
              >
                Yes
              </a>
              <a
                href={" "}
                className="link-btn font-btn text-blue ml-2"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleLogoutModal();
                }}
              >
                No
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default socketConnect(connect(mapStateToProps)(withRouter(HeaderAuth)));
