import { Component } from "react";

let guestAuth = function (ComposedComponent) {
  class GuestAuth extends Component {
    async componentDidMount() {
      // console.log(this.props);
      if (localStorage.getItem("access_token")) {
        this.props.history.push("/dashboard");
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return GuestAuth;
};

export default guestAuth;
