import { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap/";
import Carousel from "react-bootstrap/Carousel";
import { withRouter, Link, Redirect } from "react-router-dom";
import {
  faClose,
  faExpand,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import "flatpickr/dist/themes/material_blue.css";
import { socketConnect } from "socket.io-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AbstractHeaderMessage } from "../../lib/abstractComponents/message";
import { Field, Formik } from "formik";

class MessageHeader extends AbstractHeaderMessage {
  render() {
    return (
      <>
        <div className="message-group-drop-down">
          <p className="droptitle">
            Messages{" "}
            <span className="float-right icons">
              <FontAwesomeIcon
                icon={faExpand}
                className="cursor-pointer"
                onClick={() => {
                  this.props.history.push("/message");
                }}
              />
            </span>
          </p>

          <div className="searchbar-header order-1">
            <Formik
              innerRef={this.searchRef}
              initialValues={{
                search: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.searchGroup(values, setSubmitting);
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <Form onKeyDown={this.onKeyDown}>
                  <div className="position-relative">
                    <div className="form-group cstm-searchbox">
                      <Field
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search your network"
                        className={"form-control"}
                      />
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="searchbtn"
                      />
                      {values.search ? (
                        <FontAwesomeIcon
                          onClick={() => {
                            if (!isSubmitting) {
                              this.clearGroupSearch(setFieldValue);
                            }
                          }}
                          icon={faClose}
                          className="Closebtn cursor-pointer"
                        />
                      ) : null}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {(Array.isArray(this.state.messageGroupList) &&
            this.state.messageGroupList.length) ||
          (Array.isArray(this.state.messageRecomandedGroupList) &&
            this.state.messageRecomandedGroupList.length) ? (
            <>
              <div className="fullWidth" key={`messageRecomandedGroupList`}>
                {this.state.messageGroupList.map(
                  (group: any, index: number) => (
                    <a
                      href={undefined}
                      className="userMessage cursor-pointer"
                      key={`header-group-${group.id}`}
                      onClick={() => {
                        this.props.history.push(
                          `/message/group/${group?.message_group?.group_slug}`
                        );
                      }}
                    >
                      <div className="userpic">
                        <img
                          src={this.getGroupAvatar(group)}
                          alt="logo"
                          className="img-fluid round-img"
                        />
                      </div>
                      <div className="userinformation fullWidth">
                        <p className="username fullWidth">
                          <span className="half-name">
                            {this.getGroupName(group)}
                          </span>
                        </p>
                        <p className="userdegination fullWidth">
                          {this.getGroupDescription(group)}
                        </p>
                        {group.unread_count > 0 ? (
                          <span className="right-counter-msg">
                            {group.unread_count}
                          </span>
                        ) : null}
                      </div>
                    </a>
                  )
                )}
              </div>
              {Array.isArray(this.state.messageRecomandedGroupList) &&
              this.state.messageRecomandedGroupList.length ? (
                <>
                  {this.state.messageGroupFilter.search.trim() == "" ? (
                    <p className="droptitle fullWidth mb-1">
                      Recommended Chats{" "}
                    </p>
                  ) : null}
                  <div className="fullWidth">
                    {this.state.messageRecomandedGroupList.map(
                      (recomandedGroup: any, index: number) => (
                        <a
                          href={undefined}
                          className="userMessage cursor-pointer"
                          key={`header-recomanded-group-${recomandedGroup.id}`}
                          onClick={() => {
                            this.props.history.push(
                              `/message/user/${recomandedGroup?.slug}`
                            );
                          }}
                        >
                          <div className="userpic">
                            <img
                              src={this.getRecomandedGroupAvatar(
                                recomandedGroup
                              )}
                              alt="logo"
                              className="img-fluid round-img"
                            />
                          </div>
                          <div className="userinformation fullWidth">
                            <p className="username fullWidth">
                              <span className="half-name">
                                {this.getRecomandedGroupName(recomandedGroup)}
                              </span>
                            </p>
                            <p className="userdegination fullWidth">
                              {this.getRecomandedGroupDescription(
                                recomandedGroup
                              )}
                            </p>
                          </div>
                        </a>
                      )
                    )}
                  </div>
                </>
              ) : null}
            </>
          ) : !this.state.showLoading ? (
            <p className="text-center" key={"no-group"}>
              No group found
            </p>
          ) : null}
          {this.state.showLoading ? (
            <p className="text-center" key={"loading-group"}>
              Loading...
            </p>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    intl: state.intl.messages,
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default socketConnect(
  connect(mapStateToProps)(withRouter(MessageHeader))
);
