// import libs
import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

// import components
// import PrivateRoute from './Private';
import PublicRoute from "./Public";
import AuthRoute from "./Auth";
import SessionAuthRoutes from "./SessionAuth";
import Layout from "../components/Layout";
import Header from "../global/Header";
import Footer from "../global/Footer";
import UserAuth from "./UserAuth.js";
import UserSessionAuth from "./UserSessionAuth.js";

import "../assets/css/style.scss";
import "../assets/css/base/fonts/flaticons/flaticon.css";
import "../assets/css/base/fonts/font/stylesheet.css";
// import { signUp } from '../lib/apis/auth';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import guestAuth from "./GuestAuth";

const GoToTop = () => {
  const routePath = useLocation();
  const onTop = () => {
    // setTimeout(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
    window.scroll(0, 0);
    // }, 400);
  };
  useEffect(() => {
    onTop();
  }, [routePath.pathname]);

  return null;
};

const ProtectedSite = lazy(() => import("../Pages/ProtectedSite/index"));

const Login = lazy(() => import("../Pages/SignIn/index"));

const SignUp = lazy(() => import("../Pages/SignUp/index"));

// const CompleteProfile = lazy(() => import("../Pages/SignUp/CompleteProfile"));

const Forgot = lazy(() => import("../Pages/Forgot/index"));

const Home = lazy(() => import("../Pages/Home/index"));

const Resetpassword = lazy(() => import("../Pages/resetpassword/index"));

const ProfileGuest = lazy(() => import("../Pages/Profile/GuestIndex"));

const Profile = lazy(() => import("../Pages/Profile/index"));

const Terms = lazy(() => import("../Pages/CMS/Terms"));

const Privacy = lazy(() => import("../Pages/CMS/Privacy"));

const Search = lazy(() => import("../Pages/SearchPractitioners/index"));

const QuickContact = lazy(() => import("../Pages/QuickContacts/index"));

const EditProfile = lazy(() => import("../Pages/Profile/EditProfile"));

const Dashboard = lazy(() => import("../Pages/Dashboard/index"));

const ContactUs = lazy(() => import("../Pages/ContactUs/index"));

const Page404 = lazy(() => import("../Pages/Page404/index"));

const Preview = lazy(() => import("../Pages/Preview/index"));

const LandingPage = lazy(() => import("../Pages/LandingPage"));

const LeadPage = lazy(() => import("../Pages/LeadPage"));

const BlogList = lazy(() => import("../Pages/Blogs/index"));

const BlogDetails = lazy(() => import("../Pages/Blogs/description"));

const protectedKey = sessionStorage.getItem("protected_token");

const notification = lazy(() => import("../Pages/Notification/index"));

const referrals = lazy(() => import("../Pages/Referrals/index"));

const membership = lazy(() => import("../Pages/Membership/index"));

const settings = lazy(() => import("../Pages/Settings/index"));

const promoAd = lazy(() => import("../Pages/PromoAd/index"));

const Inquiries = lazy(() => import("../Pages/Inquiries/index"));

// const messageHtml = lazy(() => import("../Pages/html/Message"));

const message = lazy(() => import("../Pages/Message"));

// const editprofiles = lazy(() => import("../Pages/html/Edit-profiles/index"));

// const search = lazy(() => import("../Pages/html/Search/index"));

const mainSearch = lazy(() => import("../Pages/MainSearch/index"));

const eventDeatil = lazy(() => import("../Pages/Events/details"));

// const eventsHtml = lazy(() => import("../Pages/html/Events/index"));

const events = lazy(() => import("../Pages/Events/index"));

const exclusiveEvents = lazy(
  () => import("../Pages/Events/exclusiveEventIndex")
);

// const networkHtml = lazy(() => import("../Pages/html/Network/index"));

const network = lazy(() => import("../Pages/Network/index"));

// const eventsdetails = lazy(() => import("../Pages/html/Events/details"));

// const membershipHtml = lazy(() => import("../Pages/html/Membership/index"));

const membershipDetail = lazy(() => import("../Pages/Membership/details"));

const membershipDetailInformation = lazy(
  () => import("../Pages/Membership/information")
);

const Routes = (props: any) => (
  <Router>
    <Header {...props} />
    <Layout>
      <GoToTop />
      <Switch>
        {/* Auth */}
        <PublicRoute
          path={`${process.env.PUBLIC_URL}/login`}
          component={guestAuth(Login)}
          newProps={props}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/sign-up/:referral_code?`}
          component={guestAuth(SignUp)}
        />

        {/* <SessionAuthRoutes
          path={`${process.env.PUBLIC_URL}/complete-profile`}
          component={UserSessionAuth(CompleteProfile)}
        /> */}

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/preview/:user_slug?`}
          component={Preview}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/user-profile/:user_slug?/:zipcode?`}
          component={guestAuth(ProfileGuest)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/user/:user_slug?`}
          component={UserAuth(Profile)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/reset-password/:email?/:token?`}
          component={Resetpassword}
        />

        {/* <AuthRoute
          path={`${process.env.PUBLIC_URL}/change-password`}
          component={UserAuth(ChangePassword)}
        /> */}

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/forgot`}
          component={guestAuth(Forgot)}
        />

        {/* <AuthRoute
          path={`${process.env.PUBLIC_URL}/payment-details`}
          exact
          component={UserAuth(PaymentDetails)}
        /> */}

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/:user_slug?/quick-contact`}
          component={QuickContact}
        />

        {/* <AuthRoute
          path={`${process.env.PUBLIC_URL}/profile`}
          exact
          component={UserAuth(Profile)}
        /> */}

        {/* <AuthRoute path={`${process.env.PUBLIC_URL}/profile/:user-slug?`}
                    component={UserAuth(Profile)} /> */}

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/edit-profile`}
          component={UserAuth(EditProfile)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={UserAuth(Dashboard)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/activity/*`}
          component={UserAuth(Dashboard)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/settings`}
          component={UserAuth(settings)}
        />
        <AuthRoute
          path={`${process.env.PUBLIC_URL}/promo-ad`}
          component={UserAuth(promoAd)}
        />
        <AuthRoute
          path={`${process.env.PUBLIC_URL}/inquiries`}
          component={UserAuth(Inquiries)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/referrals`}
          component={UserAuth(referrals)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/message/:groupType?/:groupSlug?`}
          component={UserAuth(message)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/membership`}
          component={UserAuth(membership)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/notification`}
          component={UserAuth(notification)}
        />

        {/* Public */}

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={UserAuth(Home)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/why-us`}
          component={UserAuth(LandingPage)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/find-a-pro`}
          component={UserAuth(LeadPage)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/verify-email/:email?/:activation_hash?`}
          component={Home}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/home`}
          component={UserAuth(Home)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/terms`}
          component={Terms}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/privacy`}
          component={Privacy}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/search`}
          component={UserAuth(Search)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/contact-us`}
          component={UserAuth(ContactUs)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/blog`}
          component={UserAuth(BlogList)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/blog/:slug?`}
          component={UserAuth(BlogDetails)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/not-found`}
          component={UserAuth(Page404)}
        />

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/message-html`}
          component={UserAuth(messageHtml)}
        /> */}

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/editprofiles-html`}
          component={UserAuth(editprofiles)}
        /> */}

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/lsn/search`}
          component={UserAuth(mainSearch)}
        />

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/search-html`}
          component={UserAuth(search)}
        /> */}

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/events-html`}
          component={UserAuth(eventsHtml)}
        /> */}
        <AuthRoute
          path={`${process.env.PUBLIC_URL}/events`}
          component={UserAuth(events)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/exclusive-events`}
          component={UserAuth(exclusiveEvents)}
        />

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/network-html`}
          component={UserAuth(networkHtml)}
        /> */}
        <AuthRoute
          path={`${process.env.PUBLIC_URL}/network`}
          component={UserAuth(network)}
        />

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/events-details.html`}
          component={UserAuth(eventsdetails)}
        /> */}

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/events-detail/:activitySlug`}
          component={UserAuth(eventDeatil)}
        />

        {/* <PublicRoute
          path={`${process.env.PUBLIC_URL}/membership-index-html`}
          component={UserAuth(membershipHtml)}
        /> */}

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/membership-detail`}
          component={UserAuth(membershipDetail)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/become-a-member`}
          component={guestAuth(membershipDetailInformation)}
        />

        <PublicRoute path={`*`} component={UserAuth(Page404)} />
      </Switch>
    </Layout>
    <ToastContainer />
    <Footer {...props} />
  </Router>
);

const RoutesProtected = (props: any) => (
  <Router>
    <Header {...props} />
    <Layout>
      <GoToTop />
      <Switch>
        <PublicRoute path={`*`} component={ProtectedSite} />
      </Switch>
    </Layout>
    <ToastContainer />
    <Footer {...props} />
  </Router>
);

export default process.env.REACT_APP_NODE_ENV != "production" && !protectedKey
  ? RoutesProtected
  : Routes;
