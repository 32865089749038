import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { BaseUrl } from "../config/constant";

export const PublicRoutes = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense
            fallback={
              <div id="loading">
                <div className="loader-content ">
                  {/* position-relative */}
                  <img
                    src={`${BaseUrl}loader.gif`}
                    className="loading-image"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            }
          >
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  </>
);
export default PublicRoutes;
