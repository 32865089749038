import { Component } from "react";
import { withRouter } from "react-router-dom";
import { logout } from "../lib/apis/auth";
import { connect } from "react-redux";
import $ from 'jquery';
import HeaderGuest from "./HeaderGuest";
import HeaderAuth from "./HeaderAuth";

class Header extends Component<any, any> {
  constructor(props: any) {    
    super(props);
  }

  render() {
    return (
      <>
      {this.props.isAuthenticated?<HeaderAuth key={`header-${this.props.currentUser?.name}-${this.props.currentUser?.avatar}`}/>:<HeaderGuest/>}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(mapStateToProps)(withRouter(Header));
