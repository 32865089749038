import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
// import {IMAGE_URL} from '../lib/config/constant';

export const AuthRoutes = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("access_token") !== null &&
        localStorage.getItem("access_token") !== undefined &&
        localStorage.getItem("access_token") !== "" ? (
          <Suspense
            fallback={
              <div id="loading">
                <div className="loader-content">
                  {" "}
                  {/* position-relative */}
                  <img
                    src={"/loader.gif"}
                    className="loading-image"
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            }
          >
            <Component {...props} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  </>
);
export default AuthRoutes;
