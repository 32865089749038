import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { logout } from "../lib/apis/auth";
import { connect } from "react-redux";
import { BLOG_URL, ASSET_IMAGE_URL } from "../config/constant"; // ASSET_IMAGE_URL,
import $ from "jquery";

const logo = `${ASSET_IMAGE_URL}/new_logo_v2.svg`;

class HeaderGuest extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      language:
        localStorage.getItem("language") !== "" &&
        localStorage.getItem("language") !== ""
          ? localStorage.getItem("language")
          : "en",
      practitionerData:
        localStorage.getItem("practitionerData") !== ""
          ? localStorage.getItem("practitionerData")
          : "",
      showLogoutModal: false,
      showNavbar: false,
    };
  }

  componentDidMount() {
    document.onkeydown = (evt) => {
      if (evt.keyCode === 27) {
        this.setState({
          showLogoutModal: false,
        });
      }
    };
    window.addEventListener("resize", () => {
      this.setState({
        showNavbar: false,
      });
    });
    setTimeout(() => {
      document.getElementById("loading")?.classList.add("d-none");
    }, 500);
  }

  toggleNavbar = () => {
    if (this.state.showNavbar === false) {
      this.setState({
        showNavbar: true,
      });
    } else {
      this.setState({
        showNavbar: false,
      });
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    /** Scroll top on next page */
    // window.scroll(0,0)
    let minHeight = window.innerHeight - $(".custom-header").height();
    $(".main_page").css("min-height", `${minHeight}px`);
  }

  render() {
    return (
      <>
        <header id="header" className="custom-header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-sticky px-0 border-0">
              <div className="navbar-brand">
                <Link
                  to="/"
                  className="d-inline-block"
                  onClick={() => {
                    this.setState({
                      showNavbar: false,
                    });
                    setTimeout(() => {
                      window.scroll(0, 0);
                      document
                        .getElementsByTagName("body")[0]
                        .scrollTo({ top: 0, behavior: "smooth" });
                    }, 200);
                    <Redirect to="/home" />;
                  }}
                >
                  <img src={logo} alt="logo" width={218} height={50} />
                </Link>
              </div>
              <div className="d-lg-none">
                <button
                  type="button"
                  id="hamburger-1"
                  onClick={this.toggleNavbar}
                  className={
                    (this.state.showNavbar === true
                      ? " open "
                      : " collapsed ") + " navbar-toggle hamburger"
                  }
                  data-target="#navbar-navigation"
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </button>
              </div>
              <div
                className={
                  (this.state.showNavbar === true ? "show " : "hide ") +
                  " navbar-collapse collapse"
                }
                id="navbar-navigation"
              >
                <ul
                  className={
                    "login-nav p-0 flex-column flex-lg-row nav navbar-nav align-items-center ml-auto mt-2 mt-lg-0 align-items-lg-baseline align-items-lg-center"
                  }
                >
                  <>
                    <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                      <a
                        href={BLOG_URL}
                        className="navbar-nav-link"
                        // target="_blank"
                        rel="noreferrer"
                        onClick={this.toggleNavbar}
                      >
                        Blog
                      </a>
                    </li>
                    <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                      <Link
                        to="/why-us"
                        onClick={this.toggleNavbar}
                        className="navbar-nav-link"
                      >
                        Why Us
                      </Link>
                    </li>
                    <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                      <Link
                        to="/become-a-member"
                        onClick={this.toggleNavbar}
                        className="navbar-nav-link"
                      >
                        Membership
                      </Link>
                    </li>
                    <li className="nav-item float-right mr-lg-4 pt-4 pt-lg-0">
                      <Link
                        to="/login"
                        onClick={this.toggleNavbar}
                        className="navbar-nav-link"
                      >
                        Login
                      </Link>
                    </li>
                    <li className="nav-item float-right ml-2 mr-lg-4 pt-4 pt-lg-0">
                      <Link
                        to="/sign-up"
                        onClick={this.toggleNavbar}
                        className="navbar-nav-link btn-primary"
                      >
                        Join Zip Ally
                      </Link>
                    </li>
                  </>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default connect()(withRouter(HeaderGuest));
